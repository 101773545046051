/* eslint-disable */
import { gql } from "@apollo/client";

const GET_SONGS = gql`
  query GetAllAudios {
    GetAllAudios {
      id
      title
      keywords
      genre
      bpm
      mood
      producer
      recommendedFor
    }
  }
`;

const GET_MY_SONGS = gql`
  query GetAllMyAudios {
    GetAllMyAudios {
      id
      title
      keywords
      genre
      bpm
      mood
      producer
      recommendedFor
    }
  }
`;

const GET_SONG = gql`
  query GetAudio($id: String!) {
    GetAudio(id: $id) {
      audioFilePath
      bpm
      description
      genre
      keywords
      length
      masterInfo{
        price,
        masterPoints,
        currency,
        name
      }
      mood
      privacyStatus
      producer
      publishingInfo{
        name,
        ipi,
        procent,
        publisherInfo
      }
      recommendedFor
      songWriter
      theme
      title
      topLiner
      vocalist
    }
  }
`;
const SUB_SONGS = gql`
  subscription {
    audioAdded {
      id
      title
      keywords
      genre
      bpm
      mood
      producer
      recommendedFor
      vocalist
    }
  }
`;

const REMOVE_SONG = gql`
  mutation DeleteAudio($id: String!) {
    DeleteAudio(id: $id) {
      id
      title
      keywords
      genre
      bpm
      mood
      producer
      recommendedFor
      vocalist
    }
  }
`;

const BUY_SONG = gql`
  mutation Buy($id: String!) {
    Buy(id: $id) {
      id
    }
  }
`;

const CREATE_NEW_AUDIO = gql`
mutation AddAudio(
  $audioFilePath: String!
  $bpm: Float!
  $description: String!
  $genre: String!
  $keywords: String!
  $length: Float!
  $masterInfo: [NewMasterInput!]!
  $mood: String!
  $privacyStatus: String!
  $producer: String!
  $publishingInfo: [NewPublisherInput!]!
  $recommendedFor: String!
  $songWriter: String!
  $theme: String!
  $title: String!
  $topLiner: String!
  $vocalist: String!
) {
  AddAudio(
    newAudioData: {
      audioFilePath: $audioFilePath
      bpm: $bpm
      description: $description
      genre: $genre
      keywords: $keywords
      length: $length
      masterInfo: $masterInfo
      mood: $mood
      privacyStatus: $privacyStatus
      producer: $producer
      publishingInfo: $publishingInfo
      recommendedFor: $recommendedFor
      songWriter: $songWriter
      theme: $theme
      title: $title
      topLiner: $topLiner
      vocalist: $vocalist
    }
  ) {
    audioFilePath
    bpm
    description
    genre
    keywords
    length
    masterInfo{
        price
        masterPoints
        currency
    }
    mood
    privacyStatus
    producer
    publishingInfo{
      name,
      ipi,
      procent
    }
    recommendedFor
    songWriter
    theme
    title
    topLiner
    vocalist
  }
}
`;
export { GET_SONGS, GET_SONG, GET_MY_SONGS, SUB_SONGS, REMOVE_SONG, CREATE_NEW_AUDIO, BUY_SONG };
