/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { useQuery } from "@apollo/client";
import { GET_MY_SONGS, SUB_SONGS } from "graphql/songs";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
// Data
// import dataLabel from "layouts/labels/view/dataLabel";
import { useState } from "react";

function ViewMySong() {
  const navigate = useNavigate();
  const [dataX, setDataX] = useState([]);
  const [checked, setChecked] = useState(false);

  // eslint-disable-next-line
  let { loading, error, data, subscribeToMore } = useQuery(GET_MY_SONGS);

  if (!checked && data) {
    setDataX(data.GetAllMyAudios);
    setChecked(true);
  }

  if (loading) return loading;

  if (error) return error;

  subscribeToMore({
    document: SUB_SONGS,
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;
      // eslint-disable-next-line
      const audioAdded = subscriptionData.data.audioAdded;
      // eslint-disable-next-line
      return Object.assign({}, prev, {
        labels: [audioAdded, ...prev.GetAllMyAudios],
        // eslint-disable-next-line
        __typename: prev.__typename,
      });
    },
  });

  const columns = [
    { Header: "title", accessor: "title", width: "15%", align: "left" },
    { Header: "keywords", accessor: "keywords", align: "left" },
    { Header: "genre", accessor: "genre", align: "center" },
    { Header: "bpm", accessor: "bpm", align: "center" },
    { Header: "mood", accessor: "mood", align: "center" },
    { Header: "producer", accessor: "producer", align: "center" },
    { Header: "recommended For", accessor: "recommendedFor", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  // eslint-disable-next-line
  const filterTable = (tag, value) => {
    setDataX(data.GetAllMyAudios.filter((item) => item[tag] === value));
  };

  const rows =
    !loading &&
    dataX.map(({ id, title, keywords, genre, bpm, mood, producer, recommendedFor }) => ({
      title: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
          onClick={(e) => {
            e.preventDefault();
            navigate(`/song/${id}`);
          }}
        >
          {title}
        </MDTypography>
      ),
      keywords: (
        <MDTypography
          component="a"
          // eslint-disable-next-line
          href={"/song/" + id}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {keywords}
        </MDTypography>
      ),
      genre: (
        <MDTypography
          component="a"
          // eslint-disable-next-line
          href={"/song/" + id}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {genre}
        </MDTypography>
      ),
      bpm: (
        <MDTypography
          component="a"
          // eslint-disable-next-line
          href={"/song/" + id}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {bpm}
        </MDTypography>
      ),
      mood: (
        <MDTypography
          component="a"
          // eslint-disable-next-line
          href={"/song/" + id}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {mood}
        </MDTypography>
      ),
      producer: (
        <MDTypography
          component="a"
          // eslint-disable-next-line
          href={"/song/" + id}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {producer}
        </MDTypography>
      ),
      recommendedFor: (
        <MDTypography
          component="a"
          // eslint-disable-next-line
          href={"/song/" + id}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {recommendedFor}
        </MDTypography>
      ),
      action: (
        <MDButton variant="gradient" color="success" size="small" iconOnly>
          <Icon
            onClick={(e) => {
              e.preventDefault();
              navigate(`/song/${id}`);
            }}
          >
            preview
          </Icon>
        </MDButton>
      ),
    }));
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDBox>
                  <MDTypography variant="h6" color="white">
                    Songs
                  </MDTypography>
                </MDBox>
              </MDBox>
              <Grid container spacing={2}>
                <Grid item lg={2}>
                  <MDBox ml={2.5} mt={1} pt={3}>
                    <Typography variant="body1">Genre:</Typography>
                    <FormGroup>
                      {/* eslint-disable-next-line */}
                      {data.GetAllMyAudios.map((item) => {
                        if (item.genre === "") return null;
                        return (
                          <FormControlLabel
                            control={<Radio />}
                            label={item.genre}
                            onClick={(e) => {
                              e.preventDefault();
                              filterTable("genre", item.genre);
                            }}
                          />
                        );
                      })}
                    </FormGroup>
                  </MDBox>
                  <MDBox ml={2.5} mt={1} pt={3}>
                    <Typography variant="body1">Producer:</Typography>
                    <FormGroup>
                      {/* eslint-disable-next-line */}
                      {data.GetAllMyAudios.map((item) => {
                        if (item.producer === "") return null;
                        return (
                          <FormControlLabel
                            control={<Radio />}
                            label={item.producer}
                            onClick={(e) => {
                              e.preventDefault();
                              filterTable("producer", item.producer);
                            }}
                          />
                        );
                      })}
                    </FormGroup>
                  </MDBox>
                  <MDBox ml={2.5} mt={1} pt={3}>
                    <Typography variant="body1">Mood:</Typography>
                    <FormGroup>
                      {/* eslint-disable-next-line */}
                      {data.GetAllMyAudios.map((item) => {
                        if (item.mood === "") return null;
                        return (
                          <FormControlLabel
                            control={<Radio />}
                            label={item.mood}
                            onClick={(e) => {
                              e.preventDefault();
                              filterTable("mood", item.mood);
                            }}
                          />
                        );
                      })}
                    </FormGroup>
                  </MDBox>
                </Grid>
                <Grid item lg={10}>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted
                      entriesPerPage
                      canSearch
                      showTotalEntries
                      noEndBorder
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewMySong;
